<template>
  <v-dialog max-width="500" data-app v-model="opened" @input="onToggle()">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>{{ this.item_id ? 'Редактирование' : 'Создание' }}</v-card-title>
      <v-card-text>
        <v-row class="row-d-block">
          <v-btn class="mr-2" :class="!item.income?'' : 'v-btn-transp'" @click="item.income=0;$forceUpdate();">Расход
          </v-btn>
          <v-btn :class="item.income?'' : 'v-btn-transp'" @click="item.income=1;$forceUpdate();">Доход</v-btn>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Основание</v-subheader>
          <v-select
              v-model="item.type"
              item-text="name"
              item-value="value"
              :items="$store.state.user.balanceRegionsTypes ? $root.selectValues($store.state.user.balanceRegionsTypes[item.income?'income' : 'cons']) : []"
              outlined
          ></v-select>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Дата</v-subheader>
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  outlined
                  :value="$root.dateToRus(item.date)"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              />
            </template>
            <v-date-picker
                v-model="item.date"
                no-title
                scrollable
                first-day-of-week="1"
                locale="ru"
                :reactive="true"
                @input="menu = false"
            />
          </v-menu>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Дата к которой привязать операцию (необязательно)</v-subheader>
          <Date v-model="item.item_date"/>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Сумма</v-subheader>
          <v-text-field
              v-model="item.sum"
              outlined
              type="number"/>
        </v-row>
        <v-row class="row-d-block pt-2">
          <v-btn class="mr-2" :class="item.paymentType!=1?'' : 'v-btn-transp'"
                 @click="item.paymentType=2;$forceUpdate();">Нал
          </v-btn>
          <v-btn :class="item.paymentType==1?'' : 'v-btn-transp'"
                 @click="item.paymentType=1;$forceUpdate();">Безнал
          </v-btn>
        </v-row>
        <v-row class="row-d-block">
          <v-subheader>Комментарий</v-subheader>
          <v-textarea
              v-model="item.comment"
              :rows="2"
              outlined
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :loading="loading">Сохранить</v-btn>
        <template v-if="item_id">
          <v-btn v-if="!deleteItem" class="v-btn-danger" @click="deleteItem=1" :loading="loading">Удалить</v-btn>
          <v-btn class="v-btn-danger" v-if="deleteItem===1" @click="remove()" :loading="loadingRemove">
            Подтвердить удаление
          </v-btn>
          <v-btn class="v-btn-transp" v-if="deleteItem===1" @click="deleteItem=0">Отмена</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Date from "../../../components/Date.vue";

export default {
  name: "Edit",
  components: {Date},
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      opened: false,
      menu: false,
      item_id: 0,
      deleteItem: 0,
      today,
      loading: false,
      loadingRemove: false,
      item: {}
    }
  },
  methods: {
    open(item) {
      let id = +item.id;
      let income = item.income;
    // open(id = 0, income = null, date = '', type = 0, paymentType = 0) {
      this.opened = true;
      this.onToggle();
      this.item_id = id;
      this.item.income = income;
      console.log('open', id, income);
      if (this.item_id) this.load();
      else {
        this.item.paymentType = 2;
      }
    },
    onToggle() {
      this.item = {date: this.today};
      this.item_id = 0;
      this.deleteItem = 0;
    },
    close() {
      this.opened = false;
      this.onToggle();
    },
    save() {
      this.loading = true;
      this.item.sum = Math.abs(this.item.sum);
      if (!this.item.income) this.item.sum = this.$root.round(-this.item.sum);
      if (!parseInt(this.item.paymentType)) this.item.paymentType = 2;
      this.$store.state.server.request('balanceRegions/' + (this.item_id ? 'update/' + this.item_id : 'create'), this.item, (data) => {
        this.loading = false;
        this.$root.notify('Строка сохранена', 'success')
        this.$eventBus.$emit('balanceRegionsSaved')
        this.close();
      }, (data) => {
        this.$root.notify(data && data.error ? data.error : 'Ошибка сохранения', 'error');
        this.loading = false;
      });
    },
    load() {
      this.$store.state.server.request('balanceRegions/get/' + this.item_id, {}, (data) => {
        data.response.income = this.$root.round(data.response.sum) < 0 ? 0 : 1;
        data.response.sum = Math.abs(data.response.sum);
        this.item = data.response;
      });
    },
    remove() {
      this.loadingRemove = true;
      this.$store.state.server.request('balanceRegions/delete/' + this.item_id, {}, (data) => {
        this.loadingRemove = false;
        this.$root.notify('Строка удалена', 'success')
        this.$eventBus.$emit('balanceRegionsSaved')
        this.close();
      }, (data) => {
        this.$root.notify(data && data.error ? data.error : 'Ошибка удаления', 'error');
        this.loading = false;
      });
    },
  },
}
</script>